var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('role-filter', {
    staticClass: "mb-3",
    on: {
      "updated": _vm.onFilterUpdated
    },
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('v-card', [_c('v-card-text', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.fetchData
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1), _c('create-dialog', {
    on: {
      "created": _vm.fetchData
    }
  })], 1), _c('permission-table', {
    ref: "tableRef",
    attrs: {
      "loading": _vm.loading,
      "items": _vm.items,
      "items-total": _vm.itemsTotal
    },
    on: {
      "updated": _vm.fetchData,
      "options-updated": _vm.onOptionsUpdated
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }